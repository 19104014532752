/**
 * Created by Andrey Popov on 10.11.22
 */

var ClimbableView = cc.Node.extend({
    ctor: function (climbable, unitView) {
        this._super();

        this.unitView = unitView;
        this.climbable = climbable;

        var sfx = this.getClimbSfx();
        if (sfx && sfx.resolve()) {
            cleverapps.bundleLoader.ensureSoundsLoaded(sfx);
        }

        if (cleverapps.config.debugMode && Climbable.GetTrigger(climbable.unit)) {
            this.showTriggerLock();
        }
    },

    customClimb: function (newUnit, callback) {
        Merge.currentMerge.counter.inc();
        var newUnitView = newUnit.onGetView();
        newUnitView.setVisible(false);

        this.unitView.replaceParentSamePlace(Map2d.currentMap.getMapView().animations, {
            keepScale: true
        });
        this.unitView.sprite.setAnimation(0, "climb", false);
        this.unitView.sprite.setCompleteListenerOnce(function () {
            this.unitView.removeFromParent();
            newUnitView.setVisible(true);
            Merge.currentMerge.counter.dec();
            callback();
        }.bind(this));
    },

    beforeStageUpdate: function () {
        var prizeView = this.unitView.getAdditionalView("prize");
        if (prizeView) {
            prizeView.hide();
        }
    },

    hasCustomAnimation: function () {
        return this.unitView.sprite.hasAnimation && this.unitView.sprite.hasAnimation("climb");
    },

    updateStage: function (newUnit, callback) {
        if (!this.climbable.unit.getData().simpleClimb && !this.hasCustomAnimation()) {
            this.unitView.removeFromParent();
            callback();
            return;
        }

        var sound = this.getClimbSfx();
        cleverapps.audio.playSound(sound);

        if (this.hasCustomAnimation()) {
            this.customClimb(newUnit, callback);
        } else {
            this.unitView.sprite.runAction(StandartAnimations.upgrade(this.unitView, newUnit.onGetView(), {
                flash: true
            })).setFinalize(callback);
        }
    },

    getClimbSfx: function () {
        return bundles.merge.urls["climb_effect_" + Unit.GetKey(this.climbable.unit)]
        || bundles.merge.urls["climb_effect_" + this.climbable.unit.getType()];
    },

    showTriggerLock: function () {
        var styles = cleverapps.styles.ClimbableView.triggerLock;
        var triggerLock = new cleverapps.Spine(bundles.fog.jsons.fog_blocker_json);
        triggerLock.setAnimation(0, "idle", true);
        triggerLock.setPositionRound(styles);

        var trigger = Climbable.GetTrigger(this.climbable.unit);

        var target = Map2d.currentMap.listAvailableUnits().filter(function (unit) {
            return unit.code === trigger.code;
        })[0];

        if (!target) {
            target = Map2d.currentMap.fogs.listFakeUnits(function (unit) {
                return unit.code === trigger.code;
            })[0];
        }

        if (!target) {
            cleverapps.throwAsync("Climbable unit not found!: check unit: " + trigger.code + " in scene");
        }

        var iconSlot = triggerLock.getSlot("slot_icon");
        if (iconSlot) {
            var unitIcon = UnitView.getUnitImage(UnitsLibrary.LastStage(target.code), { preferStatic: true });
            cleverapps.UI.fitToBox(unitIcon, styles.icon);
            iconSlot.addChild(unitIcon);
        }

        cleverapps.UI.applyHover(triggerLock);
        cleverapps.UI.onClick(triggerLock, function () {
            cleverapps.focusManager.display({
                focus: "DebugShowTrigger",
                actions: [
                    function (f) {
                        Map2dScroller.currentScroller.zoomIn(target, {
                            zoom: 1.33
                        });
                        Map2d.currentMap.highlightUnit(target, f);
                    },

                    function (f) {
                        setTimeout(f, 1000);
                    },

                    function (f) {
                        Map2dScroller.currentScroller.zoomOut();
                        Map2d.currentMap.unhighlightUnit();
                        f();
                    }
                ]
            });
        });

        this.addChild(triggerLock);
    }
});

cleverapps.styles.ClimbableView = {
    triggerLock: {
        x: { align: "center" },
        y: { align: "center" },

        icon: {
            width: 80,
            height: 80
        }
    }
};