/**
 * Created by evgenijsenkevic on 20.09.2024
 */

var RegionControl = function (unit, options, data) {
    UnitComponent.call(this, unit);
    
    this.region = data.region;
    this.decoratorCode = data.decoratorCode;

    if (data.region.includes("fence_")) {
        this.decoratorCode = Map2d.currentMap.fences.getDecoratorCode(data.decoratorCode, data.region);
    }

    this.brokenSkin = data.brokenSkin;
    this.choiceSkins = data.choiceSkins;

    this.choices = data.choices;
    this.choice = options.choice;
   
    this.iterateDecorators(function (decorator) {
        var skin = this.getSkin(this.choice);
        decorator.setSkin(skin, true);
        if (this.choices) {
            decorator.setHandleClick(this.handleClick.bind(this));
        }
    }.bind(this));
};

RegionControl.prototype = Object.create(UnitComponent.prototype);
RegionControl.prototype.constructor = RegionControl;

RegionControl.prototype.getSkin = function (choice) {
    if (choice !== undefined) {
        return this.choiceSkins[choice];
    }
    return this.brokenSkin;
};

RegionControl.prototype.save = function (data) {
    data.choice = this.choice;
};

RegionControl.prototype.iterateDecorators = function (iterator) {
    Map2d.currentMap.regions[this.region].positions.forEach(function (position) {
        var decorators = Map2d.currentMap.decorators.getDecorators(position.x, position.y);

        var decorator = decorators && decorators.find(function (decorator) {
            return decorator.code === this.decoratorCode;
        }.bind(this));

        if (decorator) {
            iterator(decorator);
        }
    }.bind(this));
};

RegionControl.prototype.getChoice = function () {
    return this.choice;
};

RegionControl.prototype.setChoice = function (choice, f) {
    f = f || function () {};
    var minX = Number.MAX_VALUE;
    var minY = Number.MAX_VALUE;

    var maxX = Number.MIN_VALUE;
    var maxY = Number.MIN_VALUE;

    this.iterateDecorators(function (decorator) {
        minX = Math.min(decorator.x, minX);
        minY = Math.min(decorator.y, minY);

        maxX = Math.max(decorator.x, maxX);
        maxY = Math.max(decorator.y, maxY);
    });

    var silent = (this.choice !== undefined);

    if (!silent) {
        this.iterateDecorators(function (decorator) {
            decorator.setChoice(undefined, true);

            var animationDelay = RegionControl.ITERATE_DELAY * (decorator.x - minX + (decorator.y - minY) / 2);

            Map2d.currentMap.counter.setTimeout(function () {
                decorator.setChoice(choice);
            }, animationDelay);
        });

        Map2d.currentMap.counter.setTimeout(f, RegionControl.ITERATE_DELAY * (maxX - minX + (maxY - minY) / 2));
    } else {
        this.showChoice(choice);
        f();
    }

    this.choice = choice;
    this.unit.save();
};

RegionControl.prototype.showChoice = function (choice) {
    choice = choice === undefined ? this.choice : choice;
    var skin = this.getSkin(choice);
    this.iterateDecorators(function (decorator) {
        decorator.setSkin(skin, true);
    });
};

RegionControl.prototype.animateHover = function () {
    this.iterateDecorators(function (decorator) {
        decorator.animateHover();
    });
};

RegionControl.prototype.stopAnimateHover = function () {
    this.iterateDecorators(function (decorator) {
        decorator.stopAnimateHover();
    });
};

RegionControl.prototype.handleClick = function () {
    Homefix.currentHomefix.handleMakeChoiceWindow(this);
};

RegionControl.ITERATE_DELAY = 130;